"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.undoL1ToL2Alias = exports.applyL1ToL2Alias = exports.L1_TO_L2_ALIAS_OFFSET = void 0;
const ethers_1 = require("ethers");
const bn_1 = require("./bn");
exports.L1_TO_L2_ALIAS_OFFSET = '0x1111000000000000000000000000000000001111';
const applyL1ToL2Alias = (address) => {
    if (!ethers_1.ethers.utils.isAddress(address)) {
        throw new Error(`not a valid address: ${address}`);
    }
    return bn_1.bnToAddress(ethers_1.ethers.BigNumber.from(address).add(exports.L1_TO_L2_ALIAS_OFFSET));
};
exports.applyL1ToL2Alias = applyL1ToL2Alias;
const undoL1ToL2Alias = (address) => {
    if (!ethers_1.ethers.utils.isAddress(address)) {
        throw new Error(`not a valid address: ${address}`);
    }
    return bn_1.bnToAddress(ethers_1.ethers.BigNumber.from(address).sub(exports.L1_TO_L2_ALIAS_OFFSET));
};
exports.undoL1ToL2Alias = undoL1ToL2Alias;
