"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Watcher = void 0;
const ethers_1 = require("ethers");
const RELAYED_MESSAGE = ethers_1.ethers.utils.id(`RelayedMessage(bytes32)`);
const FAILED_RELAYED_MESSAGE = ethers_1.ethers.utils.id(`FailedRelayedMessage(bytes32)`);
class Watcher {
    constructor(opts) {
        this.pollInterval = 3000;
        this.blocksToFetch = 1500;
        this.pollForPending = true;
        this.l1 = opts.l1;
        this.l2 = opts.l2;
        if (typeof opts.pollInterval === 'number') {
            this.pollInterval = opts.pollInterval;
        }
        if (typeof opts.blocksToFetch === 'number') {
            this.blocksToFetch = opts.blocksToFetch;
        }
        if (typeof opts.pollForPending === 'boolean') {
            this.pollForPending = opts.pollForPending;
        }
    }
    async getMessageHashesFromL1Tx(l1TxHash) {
        return this.getMessageHashesFromTx(this.l1, l1TxHash);
    }
    async getMessageHashesFromL2Tx(l2TxHash) {
        return this.getMessageHashesFromTx(this.l2, l2TxHash);
    }
    async getL1TransactionReceipt(l2ToL1MsgHash, pollForPending) {
        return this.getTransactionReceipt(this.l1, l2ToL1MsgHash, pollForPending);
    }
    async getL2TransactionReceipt(l1ToL2MsgHash, pollForPending) {
        return this.getTransactionReceipt(this.l2, l1ToL2MsgHash, pollForPending);
    }
    async getMessageHashesFromTx(layer, txHash) {
        const receipt = await layer.provider.getTransactionReceipt(txHash);
        if (!receipt) {
            return [];
        }
        const msgHashes = [];
        const sentMessageEventId = ethers_1.ethers.utils.id('SentMessage(address,address,bytes,uint256,uint256)');
        const l2CrossDomainMessengerRelayAbi = [
            'function relayMessage(address _target,address _sender,bytes memory _message,uint256 _messageNonce)',
        ];
        const l2CrossDomainMessengerRelayinterface = new ethers_1.ethers.utils.Interface(l2CrossDomainMessengerRelayAbi);
        for (const log of receipt.logs) {
            if (log.address === layer.messengerAddress &&
                log.topics[0] === sentMessageEventId) {
                const [sender, message, messageNonce] = ethers_1.ethers.utils.defaultAbiCoder.decode(['address', 'bytes', 'uint256'], log.data);
                const [target] = ethers_1.ethers.utils.defaultAbiCoder.decode(['address'], log.topics[1]);
                const encodedMessage = l2CrossDomainMessengerRelayinterface.encodeFunctionData('relayMessage', [target, sender, message, messageNonce]);
                msgHashes.push(ethers_1.ethers.utils.solidityKeccak256(['bytes'], [encodedMessage]));
            }
        }
        return msgHashes;
    }
    async getTransactionReceipt(layer, msgHash, pollForPending) {
        if (typeof pollForPending !== 'boolean') {
            pollForPending = this.pollForPending;
        }
        let matches = [];
        let blocksToFetch = layer.blocksToFetch;
        if (typeof blocksToFetch !== 'number') {
            blocksToFetch = this.blocksToFetch;
        }
        while (matches.length === 0) {
            const blockNumber = await layer.provider.getBlockNumber();
            const startingBlock = Math.max(blockNumber - blocksToFetch, 0);
            const successFilter = {
                address: layer.messengerAddress,
                topics: [RELAYED_MESSAGE],
                fromBlock: startingBlock,
            };
            const failureFilter = {
                address: layer.messengerAddress,
                topics: [FAILED_RELAYED_MESSAGE],
                fromBlock: startingBlock,
            };
            const successLogs = await layer.provider.getLogs(successFilter);
            const failureLogs = await layer.provider.getLogs(failureFilter);
            const logs = successLogs.concat(failureLogs);
            matches = logs.filter((log) => log.topics[1] === msgHash);
            if (!pollForPending) {
                break;
            }
            await new Promise((r) => setTimeout(r, this.pollInterval));
        }
        if (matches.length > 0) {
            if (matches.length > 1) {
                throw Error('Found multiple transactions relaying the same message hash.');
            }
            return layer.provider.getTransactionReceipt(matches[0].transactionHash);
        }
        else {
            return Promise.resolve(undefined);
        }
    }
}
exports.Watcher = Watcher;
