"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bnToAddress = void 0;
const ethers_1 = require("ethers");
const hex_strings_1 = require("./common/hex-strings");
const bnToAddress = (bn) => {
    bn = ethers_1.ethers.BigNumber.from(bn);
    if (bn.isNegative()) {
        bn = ethers_1.ethers.BigNumber.from('0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF')
            .add(bn)
            .add(1);
    }
    let addr = bn.toHexString();
    addr = hex_strings_1.remove0x(addr);
    addr = addr.padStart(40, '0');
    addr = addr.slice(addr.length - 40, addr.length);
    addr = hex_strings_1.add0x(addr);
    addr = ethers_1.ethers.utils.getAddress(addr);
    return addr;
};
exports.bnToAddress = bnToAddress;
