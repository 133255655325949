"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zeroesAndOnes = exports.calculateL1Fee = exports.calculateL1GasUsed = exports.scaleDecimals = void 0;
const ethers_1 = require("ethers");
const common_1 = require("./common");
const txDataZeroGas = 4;
const txDataNonZeroGasEIP2028 = 16;
const big10 = ethers_1.BigNumber.from(10);
const scaleDecimals = (value, decimals) => {
    value = ethers_1.BigNumber.from(value);
    decimals = ethers_1.BigNumber.from(decimals);
    const divisor = big10.pow(decimals);
    return value.div(divisor);
};
exports.scaleDecimals = scaleDecimals;
const calculateL1GasUsed = (data, overhead) => {
    const [zeroes, ones] = exports.zeroesAndOnes(data);
    const zeroesCost = zeroes * txDataZeroGas;
    const onesCost = (ones + 68) * txDataNonZeroGasEIP2028;
    return ethers_1.BigNumber.from(onesCost).add(zeroesCost).add(overhead);
};
exports.calculateL1GasUsed = calculateL1GasUsed;
const calculateL1Fee = (data, overhead, l1GasPrice, scalar, decimals) => {
    const l1GasUsed = exports.calculateL1GasUsed(data, overhead);
    const l1Fee = l1GasUsed.mul(l1GasPrice);
    const scaled = l1Fee.mul(scalar);
    const result = exports.scaleDecimals(scaled, decimals);
    return result;
};
exports.calculateL1Fee = calculateL1Fee;
const zeroesAndOnes = (data) => {
    if (typeof data === 'string') {
        data = Buffer.from(common_1.remove0x(data), 'hex');
    }
    let zeros = 0;
    let ones = 0;
    for (const byte of data) {
        if (byte === 0) {
            zeros++;
        }
        else {
            ones++;
        }
    }
    return [zeros, ones];
};
exports.zeroesAndOnes = zeroesAndOnes;
